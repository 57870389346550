import React, { useState, useEffect, useCallback } from "react";
import { saveAs } from 'file-saver';


// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Button, 
  Form,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Label,
  Table,
} from "reactstrap";
// APi
import Dataservices from "../../services/requestApi";
import queryString from "query-string";
// core components
import Header from "components/Headers/Header";
import AddArticle from "components/Article/AddArticle";
import AllArticle from "components/Article/AllArticle";
import Loader from "components/loader/Loader";
import { message } from "antd";

function STDH() {
    const [title, settitle] = useState("");
    const [thumbnail, setThumbnail] = useState(null);
    const [image, setImage] = useState(null);
    const [AllPDF, setAllPDF] = useState([]);
    const [update, setUpdate] = useState(false);
    const [bannerid, setBannerID] = useState("");
    const [ready, setReady] = useState(false);
    const [description, setDescription] = useState("");
    const [search, setSearch] = useState("");
    const [type, setType] = useState("");
    const [pdf, setPDF] = useState("");
    // Addarticle
    const Addarticle = async (e) => {
      e.preventDefault();
      const data = new FormData();
      data.append("title", title);
      data.append("description", description);
      data.append("image", image);
      try {
        setReady(true);
        const res = await Dataservices.ArticleAdd(data);
        message.success(res.data.message);
        settitle("");
        setImage(null);
        setThumbnail(null);
        setDescription("");
        setReady(false);
        getAllPDF();
      } catch (e) {
        console.log(e);
      }
    };
  
    // getAllPDF
    const getAllPDF = useCallback(async (e) => {
      try {
        const res = await Dataservices.getAllPdf();

        console.log(res);
        setAllPDF(res.data.data);
      } catch (e) {
        console.log(e);
      }
    }, []);
  
    useEffect(() => {
      getAllPDF();
    }, [getAllPDF]);
  
    // article Status
    const bannerUpdateStatus = async (e, data) => {
      e.preventDefault();
      setUpdate(true);
      settitle(data.title);
      setDescription(data.description);
      setImage(data.image);
      setThumbnail(data.image);
      setBannerID(data._id);
    };
    // Status
    const status = async (e, val) => {
      e.preventDefault();
      try {
        const Data = {
          status: !val.status,
        };
        await Dataservices.ArticleUpdate(val._id, queryString.stringify(Data));
        window.location.reload(false);
      } catch (error) {
        console.log(error);
      }
    };
    // article Updates
    const updateFilter = async (e) => {
      e.preventDefault();
      setReady(true);
      try {
        if (image) {
          const data = new FormData();
          data.append("title", title);
          data.append("description", description);
          data.append("image", image);
          const res = await Dataservices.ArticleUpdate(bannerid, data);
          if (res.status) {
            message.success("Update Successfully");
            setReady(false);
            settitle("");
            setImage(null);
            setThumbnail(null);
            setDescription("");
            getAllPDF();
            setUpdate(false);
          }
          console.log(res.data);
        } else {
          message.error("Please Upload Image");
        }
      } catch (error) {
        console.log(error);
      }
    };
    // Delete banner
    const deleteBanner = async (id) => {
      setReady(true);
      try {
        await Dataservices.ArticleDelete(id);
        setReady(false);
        message.success("Article Delete Successfully");
        getAllPDF();
      } catch (error) {
        console.log(error);
      }
    };
    // const searchFilter = Allbanner.filter((data) => {
    //   return data.title.toLowerCase().includes(search.toLowerCase());
    // });
    const UploadPDF= async (e) => {
            e.preventDefault();
            if(type&&pdf)
            {
                const data = new FormData();
                console.log('typeof', type)
                data.append("type", type);
                data.append("pdf", pdf);
                data.append("name", "demo");
                try {
                setReady(true);
                const res = await Dataservices.PdfUpload(data);
                message.success(res.data.message);
                getAllPDF();
                setType("");
                setReady(false);
                setPDF(null);
                } catch (e) {
                console.log(e);
                }
            }
    }
    const deletePDF=async (id)=>{
        const res = await Dataservices.deletePDF(id);
        getAllPDF();
    }
    const fileHandle = (e) => {
        const file = e.target.files[0];
        if (file) {
            setPDF(file);
        }
      };
    return (
      <>
        <Header show={false} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col xl="4">
              <Card className="card-profile shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Upload PDF</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                  <Row>
                    <Col lg="12">
                        <Form role="form" onSubmit={UploadPDF}>
                            <FormGroup>
                            <label className="form-control-label" htmlFor="type">
                                Type
                            </label>
                            <Input
                                className="form-control-alternative"
                                id="type"
                                placeholder="/all-product"
                                type="select"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                <option value="">Select</option>
                                <option value="Vedas & Upnishd">Vedas & Upnishd</option>
                                <option value="For Pandit Ji">For Pandit Ji</option>
                                <option value="Stotra">Stotra</option>
                            </Input>
                            </FormGroup>
                            <FormGroup>
                            <label className="form-control-label" htmlFor="pdf">
                                PDF
                            </label>
                            <Input
                                className="form-control-alternative"
                                id="pdf"
                                type="file"
                                accept=".pdf"
                                onChange={fileHandle}
                                required
                            />
                            </FormGroup>
                            <Button
                                className="my-4 btn-block"
                                type="submit"
                                color="warning"
                            >
                                Upload PDF
                            </Button>
                        </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="9">
                      <h3 className="mb-0">All PDF</h3>
                    </Col>
                    <Col lg="3">
                      <FormGroup className="mb-0">
                        <Input
                          className="form-control-alternative border shadow"
                          id="title"
                          placeholder="Search Banner"
                          type="text"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <Loader show={ready} />
                <CardBody>
                   <>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Type</th>
                                <th scope="col">PDF</th>
                                <th scope="col" className="text-right">
                                Action
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {AllPDF &&
                                AllPDF.map((item, index) => (
                                <tr key={item._id}>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                    <Media className="align-items-center">
                                        <Media>
                                        <span className="mb-0 text-sm">{item.type}</span>
                                        </Media>
                                    </Media>
                                    </td>
                                    <th scope="row">
                                    <Media className="align-items-center">
                                        <Media>
                                        <span className="mb-0 text-sm">{item.pdf_name}</span>
                                        </Media>
                                    </Media>
                                    </th>
                                    <td className="text-right">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                        className="btn-icon-only text-light"
                                        href="#pablo"
                                        role="button"
                                        size="sm"
                                        color=""
                                        onClick={(e) => e.preventDefault()}
                                        >
                                        <i className="fas fa-ellipsis-v" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                        <DropdownItem
                                             href="#pablo" 
                                             onClick={(e) => saveAs(`http://159.65.144.49:5200/public${item.pdf.slice(25)}`,`${item.pdf_name}`) }
                                        >
                                            <i className="fas fa-download text-primary" /> Download
                                        </DropdownItem>
                                        <DropdownItem
                                            href={`http://159.65.144.49:5200/public${item.pdf.slice(25)}`}
                                         

                                            target="_blank"
                                        >
                                            <i className="fas fa-eye text-danger" /> View
                                        </DropdownItem>
                                        <DropdownItem
                                            href="#pablo"
                                            onClick={(e) => deletePDF(item._id)}
                                        >
                                            <i className="fas fa-trash text-danger" /> Delete
                                        </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </Table>
                        </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
}

export default STDH