import React, { useEffect, useState } from 'react'
import Dataservices from "../../services/requestApi";
import Header from "components/Headers/Header";
import { Card, Col, Container, Row, CardHeader, CardBody, Spinner } from 'reactstrap';
import moment from "moment"
import { BASEURL } from 'services/http-common';
import PlacedOrder from 'components/Shiprocket/PlacedOrder';
import AddDataModal from 'components/Modal/AddDataModal';
import {message } from "antd";

function View1(props) {

    const [data, setData] = useState()
    const [products, setProducts] = useState([]);
    const [variants, setVariants] = useState([])
    const [modal, setModal] = useState(false);
    const [ready, setReady] = useState(false);

    const HandleModal = () => {
        setModal((data) => !data);
    };

    const statusColor = {
        'In Process': 'btn-outline-primary',
        'Order Dispatched': 'btn-outline-warning',
        'Out for Delivery': 'btn-outline-info',
        'Order Delivered': 'btn-outline-success',
        'Cancelled': 'btn-outline-danger'
    }
    const getData = async () => {
        if (props.location.state) {
            const Id = props.location.state.ID;
            const fetchData = await Dataservices.getOrderByID(Id);
            console.log(fetchData.data.data)
            console.log('my console is 😊👍', fetchData.data.data.product_detiles)
            setData(fetchData.data.data);

            fetchData.data.data.product_detiles.forEach(async item => {
                const getProduct = await Dataservices.ProductById(item.product_id)
                console.log('check product', getProduct);
                if (getProduct) {
                    setProducts([getProduct.data.data]);
                    if (item.variant_id != null) {
                        const getVarient = await Dataservices.VarientById(item.variant_id);
                        setVariants([getVarient.data.data]);
                    }
                }
            });
        }
    }
    const downloadInvoice = async () => {
        if (props.location.state) {
            setReady(true);
            const Id = props.location.state.ID;
            const res = await Dataservices.getInvoice(Id);
            console.log(res);
            if ('url' in res.data) {
                window.open(res.data.url, '_blank');
            }
            else {
                message.error("Something Went Wrong");
            }
            setReady(false);
        }
    }
    const getVarient = (ID) => {
        let resultObject = variants.find(item => {
            if (item.product_id == ID) {
                return item;
            }
        });
        return resultObject;

    }
    useEffect(() => {
        getData();
    }, [])
    useEffect(() => {
        console.log('products list 😮', products)
    }, [products])
    useEffect(() => {
        console.log('variants list 🎉', variants)
    }, [variants])

    if (data == undefined) {
        return null
    }

    <h4>Address: {data.contact_address}, {data.sector}, {data.landmark}, {data.city}({data.pincode}), {data.state}</h4>

    //  {products.map((item,key)=>{
    //      return <h4 key={key}> Product : {item.name}{getVarient(item._id)&&<span>(color: <span style={{ background:getVarient(item._id).colour,display:'inline-block',height:'0.8rem',width:'0.8rem',borderRadius:'50%' }}></span> )</span>} {data.product_detiles[key].size&&`Size : ${data.product_detiles[key].size}`}</h4>
    // })}
    // return (
    //     <>
    //         <Header show={false} />
    //         <Container className="mt--7" fluid>
    //             <Row>
    //                 <Col className="order-xl-1" xl="12">
    //                     <Card className="shadow">
    //                         <CardHeader className="bg-white border-0">
    //                             <Row className="align-items-center">
    //                                 <Col xs="9">
    //                                     <h3 className="mb-0">Product Order- {data.order_id}</h3>
    //                                 </Col>
    //                             </Row>
    //                         </CardHeader>
    //                         <CardBody>
    //                             <h4>Order_id: {data._id}</h4>
    //                             <h4>Name: {data.contact_name}</h4>
    //                             <h4>Phone: {data.contact_phone}</h4>

    //                             <h4>Order Payment_status: {data.Payment_status}</h4>
    //                             <h4> Status: {data.status}</h4>
    //                             <h4> Amount: {data.amount}</h4>

    //                              {products.map((item,key)=>{
    //                                  return <h4 key={key}> Product : {item.name}{getVarient(item._id)&&<span>(color: <span style={{ background:getVarient(item._id).colour,display:'inline-block',height:'0.8rem',width:'0.8rem',borderRadius:'50%' }}></span> )</span>} {data.product_detiles[key].size&&`Size : ${data.product_detiles[key].size}`}</h4>
    //                             })}
    //                             <h4>Order Placed: {moment(data.created_at).format("llll")}</h4>
    //                         </CardBody>
    //                     </Card>
    //                 </Col>
    //             </Row>
    //         </Container>
    //     </>
    // )

    return (
        Boolean(data && products && variants)? <>
            <Header show={false} />
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <div className="card px-2 shadow">
                            <div className="card-header bg-white">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <p className="text-muted"> Order ID  <span className="font-weight-bold text-dark">{data._id}</span></p>
                                        <p className="text-muted"> Place On <span className="font-weight-bold text-dark">{moment(data.created_at).utcOffset("+00:00").format("llll")}</span> </p>
                                    </div>
                                    <div className="flex-col mt-auto d-flex flex-column">
                                        <a href={`tel:${data.contact_phone}`} className="ml-auto mr-3 text-muted">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={20} height={20}><path fill="none" d="M0 0h24v24H0z" /><path d="M21 16.42v3.536a1 1 0 0 1-.93.998c-.437.03-.794.046-1.07.046-8.837 0-16-7.163-16-16 0-.276.015-.633.046-1.07A1 1 0 0 1 4.044 3H7.58a.5.5 0 0 1 .498.45c.023.23.044.413.064.552A13.901 13.901 0 0 0 9.35 8.003c.095.2.033.439-.147.567l-2.158 1.542a13.047 13.047 0 0 0 6.844 6.844l1.54-2.154a.462.462 0 0 1 .573-.149 13.901 13.901 0 0 0 4 1.205c.139.02.322.042.55.064a.5.5 0 0 1 .449.498z" /></svg>
                                            {' '}<span className='font-weight-bold text-dark d-inline-block' style={{fontSize:'1rem'}}>{data.contact_phone}</span>
                                        </a>
                                        <p className="ml-auto mr-3 text-muted">
                                            Placed by <span className='font-weight-bold text-dark d-inline-block' style={{fontSize:'1rem'}}>{data.user}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="media flex-column flex-sm-row">
                                    <div className="media-body ">
                                        {products.map((item, key) => {
                                            return item && <h5 key={key} className="bold">{item.name}</h5>
                                        })}
                                        <p className="text-muted"> Qt: <span className='font-weight-bold text-dark'>{data.quantity}</span></p>
                                        {data?.size && <p className="text-muted"> Size: <span className='badge badge-primary btn-sm'>{data?.size}</span></p>}
                                        <h4 className="mt-3 mb-1 bold"> <span className="mt-5">₹</span> {data.amount} <span className="large text-muted"> via ({data.Payment_status == "ACTIVE" ? 'Pending' : data.Payment_status}) </span></h4>
                                        <h4 className="mt-1 mb-1 bold"> <span className="mt-5">#</span> {data.shipment_id ?? 'NA'} <span className="large text-muted">(shiprocket Id)</span></h4>
                                        <h4 className="mt-1 mb-1 bold"> <span className="mt-5">#</span> {data.shipment_order_id ?? 'NA'} <span className="large text-muted">(shiprocket Order Id)</span></h4>
                                        <p className="text-muted">Last Updated on: <span className="Today">{moment(data.updated_at).utcOffset("+00:00").format("llll")}</span></p>
                                        <button type="button" className={`btn ${statusColor[data.status]} d-flex`}>{data.status}</button>
                                    </div>
                                    {products.map((item, key) => {
                                        return item && <a key={key} href={`https://www.pujyapanditg.com/product/${item.slug}`} target="_blank" ><img className="align-self-center img-fluid" src={`${BASEURL.ENDPOINT_URL}${data.product_detiles[0].variant_id == null ? products[0].image[0].image_link : getVarient(item._id) && getVarient(item._id).image[0].image}`} width={180} height={180} /></a>
                                    })}
                                </div>
                            </div>
                            <div className="card-footer  bg-white px-sm-3 pt-sm-4 px-0">
                                <h4 className='d-inline-block'><span className='text-muted' >Name</span>: {data.contact_name}</h4>
                                <button type="button" name="" id="" onClick={downloadInvoice} className="btn btn-sm d-inline-block float-right" style={{backgroundColor:"#ffc521"}}>{ready && <Spinner size="sm" color="dark" />} Invoice</button>
                                <button type="button" name="" id="" onClick={HandleModal} className="btn btn-sm btn-primary d-inline-block float-right"> Shiprocket </button>

                                <h4>Address: {data.contact_address}, {data.sector}, {data.landmark}, {data.city}({data.pincode}), {data.state}</h4>
                                {products.map((item, key) => {
                                    return <h4 key={key}> Product : {item.name}{getVarient(item._id) && <span>(color: <span style={{ background: getVarient(item._id).colour, display: 'inline-block', height: '0.8rem', width: '0.8rem', borderRadius: '50%' }}></span> )</span>} {data.product_detiles[key]?.size && `Size : ${data.product_detiles[key]?.size}`}</h4>
                                })}
                            </div>
                            <AddDataModal
                                title="Shiprocket Order Details"
                                show={modal}
                                hide={setModal}
                                afterclose={getData}
                                width="700px"
                            >
                                <PlacedOrder Id={data._id} hide={setModal} />
                            </AddDataModal>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>: 
        <div className="d-flex justify-content-center align-items-center" style={{height:'100vh'}}>
            <Spinner color="primary" />
        </div>
    )
}

export default View1