import React, { useState, useEffect, useCallback } from "react";

  
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Button, 
  Form,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Label,
  Table,
} from "reactstrap";
// APi
import Dataservices from "../../services/requestApi";
import queryString from "query-string";
// core components
import Header from "components/Headers/Header";
import AddArticle from "components/Article/AddArticle";
import AllArticle from "components/Article/AllArticle";
import Loader from "components/loader/Loader";
import { message } from "antd";

function AllUser() {
    const [title, settitle] = useState("");
    const [thumbnail, setThumbnail] = useState(null);
    const [image, setImage] = useState(null);
    const [AllPDF, setAllPDF] = useState([]);
    const [update, setUpdate] = useState(false);
    const [bannerid, setBannerID] = useState("");
    const [ready, setReady] = useState(false);
    const [description, setDescription] = useState("");
    const [search, setSearch] = useState("");
    const [type, setType] = useState("");
    const [pdf, setPDF] = useState("");
    // Addarticle
    const Addarticle = async (e) => {
      e.preventDefault();
      const data = new FormData();
      data.append("title", title);
      data.append("description", description);
      data.append("image", image);
      try {
        setReady(true);
        const res = await Dataservices.ArticleAdd(data);
        message.success(res.data.message);
        settitle("");
        setImage(null);
        setThumbnail(null);
        setDescription("");
        setReady(false);
        getAllPDF();
      } catch (e) {
        console.log(e);
      }
    };
  
    // getAllPDF
    const getAllPDF = useCallback(async (e) => {
      try {
        const res = await Dataservices.getAllUser();
        console.log(res.data);
        setAllPDF(res.data);
      } catch (e) {
        console.log(e);
      }
    }, []);

    const deleteUser=async (id)=>{
      try {
        const res = await Dataservices.deleteUser(id);
        getAllPDF();
      } catch (e) {
        console.log(e);
      }
    }
    const blockUnBlock=async (id,status)=>{
      try {
        const Data = {
          status: !status,
        };
        await Dataservices.blockUnBlock(id, queryString.stringify(Data));
        getAllPDF();
      } catch (e) {
        console.log(e);
      }
    }
  
    useEffect(() => {
      getAllPDF();
    }, [getAllPDF]);
  
    // article Status
    const bannerUpdateStatus = async (e, data) => {
      e.preventDefault();
      setUpdate(true);
      settitle(data.title);
      setDescription(data.description);
      setImage(data.image);
      setThumbnail(data.image);
      setBannerID(data._id);
    };
    // Status
    const status = async (e, val) => {
      e.preventDefault();
      try {
        const Data = {
          status: !val.status,
        };
        await Dataservices.ArticleUpdate(val._id, queryString.stringify(Data));
        window.location.reload(false);
      } catch (error) {
        console.log(error);
      }
    };
    // article Updates
    const updateFilter = async (e) => {
      e.preventDefault();
      setReady(true);
      try {
        if (image) {
          const data = new FormData();
          data.append("title", title);
          data.append("description", description);
          data.append("image", image);
          const res = await Dataservices.ArticleUpdate(bannerid, data);
          if (res.status) {
            message.success("Update Successfully");
            setReady(false);
            settitle("");
            setImage(null);
            setThumbnail(null);
            setDescription("");
            getAllPDF();
            setUpdate(false);
          }
          console.log(res.data);
        } else {
          message.error("Please Upload Image");
        }
      } catch (error) {
        console.log(error);
      }
    };
    // Delete banner
    const deleteBanner = async (id) => {
      setReady(true);
      try {
        await Dataservices.ArticleDelete(id);
        setReady(false);
        message.success("Article Delete Successfully");
        getAllPDF();
      } catch (error) {
        console.log(error);
      }
    };
    // const searchFilter = Allbanner.filter((data) => {
    //   return data.title.toLowerCase().includes(search.toLowerCase());
    // });
    const UploadPDF= async (e) => {
            e.preventDefault();
            if(type&&pdf)
            {
                const data = new FormData();
                data.append("type", type);
                data.append("pdf", pdf);
                data.append("name", "demo");
                try {
                setReady(true);
                const res = await Dataservices.PdfUpload(data);
                message.success(res.data.message);
                setType("");
                setPDF(null);
                } catch (e) {
                console.log(e);
                }
            }
    }
    const fileHandle = (e) => {
        const file = e.target.files[0];
        if (file) {
            setPDF(file);
        }
      };
    return (
      <>
        <Header show={false} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="9">
                      <h3 className="mb-0">All User</h3>
                    </Col>
                    <Col lg="3">
                      <FormGroup className="mb-0">
                        <Input
                          className="form-control-alternative border shadow"
                          id="title"
                          placeholder="Search Banner"
                          type="text"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <Loader show={ready} />
                <CardBody>
                   <>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone</th>
                                <th scope="col" className="text-right">
                                Action
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {AllPDF &&
                                AllPDF.map((item, index) => (
                                <tr key={item._id}>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                    <Media className="align-items-center">
                                        <Media>
                                        <span className="mb-0 text-sm">{item.name}</span>
                                        </Media>
                                    </Media>
                                    </td>
                                    <th scope="row">
                                    <Media className="align-items-center">
                                        <Media>
                                        <span className="mb-0 text-sm">{item.email}</span>
                                        </Media>
                                    </Media>
                                    </th>
                                    <td>
                                    <Media className="align-items-center">
                                        <Media>
                                        <span className="mb-0 text-sm">{item.phone}</span>
                                        </Media>
                                    </Media>
                                    </td>
                                    <td className="text-right">
                                      {/* <i class="fa fa-unlock" aria-hidden="true"></i> */}
                                      <button className={`btn ${item.status?`btn-outline-danger`:`btn-outline-success`} btn-sm`} onClick={()=>{blockUnBlock(item._id,item.status)}}><i className={`fas ${item.status?'fa-lock text-danger':'fa-unlock text-success'} mr-1`} />{item.status==true?'Block':'Unblock'}</button>
                                      <button className="btn btn-outline-danger btn-sm" onClick={()=>deleteUser(item._id)}><i className="fas fa-trash text-danger" />&nbsp;Delete</button>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </Table>
                        </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
}

export default AllUser