import React, { useState, useEffect, useCallback } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  Form,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Label,
  Table,
} from "reactstrap";
// APi
import Dataservices from "../../services/requestApi";
import queryString from "query-string";
// core components
import Header from "components/Headers/Header";
import AddArticle from "components/Article/AddArticle";
import AllArticle from "components/Article/AllArticle";
import Loader from "components/loader/Loader";
import { message } from "antd";
import { Link } from "react-router-dom";


function EpujaOrder() {
  const [title, settitle] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [image, setImage] = useState(null);
  const [AllProductOrder, setAllProductOrder] = useState([]);
  const [AllPujaOrder, setAllPujaOrder] = useState([]);
  const [update, setUpdate] = useState(false);
  const [bannerid, setBannerID] = useState("");
  const [ready, setReady] = useState(false);
  const [description, setDescription] = useState("");
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [pdf, setPDF] = useState("");
  const [orderStatus, setStatus] = useState("");
  // Addarticle
  const Addarticle = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", title);
    data.append("description", description);
    data.append("image", image);
    try {
      setReady(true);
      const res = await Dataservices.ArticleAdd(data);
        {console.log("order",res)}
      message.success(res.data.message);
      settitle("");
      setImage(null);
      setThumbnail(null);
      setDescription("");
      setReady(false);
      getAllPDF();
    } catch (e) {
      console.log(e);
    }
  };

  // getAllPDF
  const getAllPDF = useCallback(async (e) => {
    try {
      const res = await Dataservices.getAllOrder();
      console.log("order",res.data.data);
      setAllProductOrder(res.data.data);

    } catch (e) {
      console.log(e);
    }
  }, []);
  const getAllPuja = useCallback(async (e) => {
    try {
      const res = await Dataservices.getAllOrderPuja();
      console.log(res.data.data);
      setAllPujaOrder(res.data.data);
    } catch (e) {
      console.log(e);
    }
  }, []);
  const updateProudctOrder = async (id, data) => {
    try {
      await Dataservices.updateProudctOrder(id, queryString.stringify(data));
      getAllPDF();
    } catch (e) {
      console.log(e);
    }
  }
  const updatePujaOrder = async (id, data) => {
    try {
      await Dataservices.updateOrderPuja(id, queryString.stringify(data));
      getAllPuja();
    } catch (e) {
      console.log(e);
    }
  }
  const deleteOrder = async (id) => {
    try {
      await Dataservices.deleteOrder(id);
      getAllPDF();
    } catch (e) {
      console.log(e);
    }
  }
  const deletePuja = async (id) => {
    try {
      await Dataservices.deletePuja(id);
      getAllPuja();
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    getAllPDF();
    getAllPuja();
  }, [getAllPDF, getAllPuja]);

  // article Status
  const bannerUpdateStatus = async (e, data) => {
    e.preventDefault();
    setUpdate(true);
    settitle(data.title);
    setDescription(data.description);
    setImage(data.image);
    setThumbnail(data.image);
    setBannerID(data._id);
  };
  // Status
  const status = async (e, val) => {
    e.preventDefault();
    try {
      const Data = {
        status: !val.status,
      };
      await Dataservices.ArticleUpdate(val._id, queryString.stringify(Data));
      window.location.reload(false);
    } catch (error) {
      console.log(error);
    }
  };
  // article Updates
  const updateFilter = async (e) => {
    e.preventDefault();
    setReady(true);
    try {
      if (image) {
        const data = new FormData();
        data.append("title", title);
        data.append("description", description);
        data.append("image", image);
        const res = await Dataservices.ArticleUpdate(bannerid, data);
        if (res.status) {
          message.success("Update Successfully");
          setReady(false);
          settitle("");
          setImage(null);
          setThumbnail(null);
          setDescription("");
          getAllPDF();
          setUpdate(false);
        }
        console.log(res.data);
      } else {
        message.error("Please Upload Image");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Delete banner
  const deleteBanner = async (id) => {
    setReady(true);
    try {
      await Dataservices.ArticleDelete(id);
      setReady(false);
      message.success("Article Delete Successfully");
      getAllPDF();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow mt-4">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="9">
                    <h3 className="mb-0">Pujas Orders</h3>
                  </Col>
                  <Col lg="3">
                    <FormGroup className="mb-0">
                      <Input
                        className="form-control-alternative border shadow"
                        id="title"
                        placeholder="Search Banner"
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>
              <Loader show={ready} />
              <CardBody>
                <>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Name</th>
                        <th scope="col">Payment Status</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Address</th>
                        <th scope="col">pandit Want</th>
                        <th scope="col">Start-Date</th>
                        <th scope="col">End-Date</th>
                        <th scope="col">Amount</th>
                        <th scope="col">status</th>
                        <th scope="col" className="text-right">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {AllPujaOrder &&
                        AllPujaOrder.map((item, index) => (
                          item.puja_id&&item.puja_id.epooja==true&&<tr key={item._id}>
                            <th scope="row">{AllPujaOrder.length-index}</th>
                            <td>
                              <Media className="align-items-center">
                                <Media>
                                  <span className="mb-0 text-sm">{item.contact_name}</span>
                                </Media>
                              </Media>
                            </td>
                            <th scope="row">
                              <Media className="align-items-center">
                                <Media>
                                  <span className="mb-0 text-sm">{item.Payment_status}</span>
                                </Media>
                              </Media>
                            </th>
                            <td>
                              <Media className="align-items-center">
                                <Media>
                                  <span className="mb-0 text-sm">{item.contact_phone}</span>
                                </Media>
                              </Media>
                            </td>
                            <td>
                              <Media className="align-items-center">
                                <Media>
                                  <span className="mb-0 text-sm">{item.contact_address}</span>
                                </Media>
                              </Media>
                            </td>
                            <td>
                              <Media className="align-items-center">
                                <Media>
                                  <span className="mb-0 text-sm">{item.pnaditCount}</span>
                                </Media>
                              </Media>
                            </td>
                            <td>
                              <Media className="align-items-center">
                                <Media>
                                  <span className="mb-0 text-sm">{item.puja_date.substr(0,10)}</span>
                                </Media>
                              </Media>
                            </td>
                            <td>
                              <Media className="align-items-center">
                                <Media>
                                  <span className="mb-0 text-sm">{item.puja_enddate.substr(0,10)}</span>
                                </Media>
                              </Media>
                            </td>
                            <td>
                              <Media className="align-items-center">
                                <Media>
                                  <span className="mb-0 text-sm">{item.amount}</span>
                                </Media>
                              </Media>
                            </td>
                            <td>
                              <FormGroup className="w-75">
                                {/* <Label for="exampleSelect">
                                          Update Status
                                        </Label> */}
                                <Input
                                  id="exampleSelect"
                                  name="select"
                                  type="select"
                                  onChange={(e) => { updatePujaOrder(item._id, { status: e.target.value }) }}
                                  defaultValue={item.status}
                                >
                                  <option value='' >
                                    Update Status
                                  </option>
                                  <option value='Order Confirmed'>
                                    Order Confirmed
                                  </option>
                                  <option value='In Process'>
                                    In Process
                                  </option>
                                  <option value='Completed'>
                                    Completed
                                  </option>
                                  <option value='Cancelled'>
                                    Cancelled
                                  </option>
                                </Input>
                              </FormGroup>
                            </td>
                            <td className="text-right">
                              <Link className="btn btn-outline-primary btn-sm" to={{pathname:`view`,state:{ID:item._id}}}>
                                <i className="fas fa-eye text-primary" /> View
                              </Link>
                              <button className="btn btn-outline-danger btn-sm" onClick={()=>deletePuja(item._id)}>
                                <i className="fas fa-trash text-danger" /> Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default EpujaOrder