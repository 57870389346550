import React, { useState } from "react";
import { FormGroup, Input, Button, Form } from "reactstrap";
import { BASEURL } from "services/http-common";
import Resizer from "react-image-file-resizer";


const AddBanner = ({
  title,
  settitle,
  setimage,
  type,
  settype,
  setURL,
  url,
  addbanner,
  updatebanner,
  editstatus,
  thumbnail,
  setthumbnail,
}) => {
  const [toggle, setToggle] = useState(false)
  const fileHandle = (e) => {

    const file = e.target.files[0];
    if (file) {
      Resizer.imageFileResizer(
        e.target.files[0],
         1520,
        500,
        "JPEG",
        50,
        0,
        (uri) => {
          setToggle(true)
          setimage(uri);
          const showiMG = URL.createObjectURL(file);
          setthumbnail(showiMG);
        },
        "file",
        1520,
        500
      );
    }
  };
  return (
    <>
      <Form role="form" onSubmit={addbanner}>
        <FormGroup>
          <label className="form-control-label" htmlFor="title">
            Title
          </label>
          <Input
            className="form-control-alternative"
            id="title"
            placeholder="Enter Title"
            type="text"
            value={title}
            onChange={(e) => settitle(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <label className="form-control-label" htmlFor="title">
            URL
          </label>
          <Input
            className="form-control-alternative"
            id=""
            placeholder="Enter Title"
            type="text"
            value={url}
            onChange={(e) => setURL(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <label className="form-control-label" htmlFor="url">
            Type
          </label>
          <Input
            className="form-control-alternative"
            id="url"
            placeholder="/all-product"
            type="select"
            value={type}
            onChange={(e) => settype(e.target.value)}
          >
            <option value="">Select</option>
            <option value="homepage">Homepage</option>
            <option value="puja">Pooja</option>
            <option value="product">Product</option>
            {/* <option value="service">Landing Image</option> */}
          </Input>
        </FormGroup>
        <FormGroup>
          <label className="form-control-label" htmlFor="image">
            Image
          </label>
          <Input
            className="form-control-alternative"
            id="image"
            type="file"
            accept="image/*"
            onChange={fileHandle}
            required
          />
        </FormGroup>
        <FormGroup>
          {!toggle&& thumbnail && (
            <img src={`${BASEURL.ENDPOINT_URL}${thumbnail}`} className="img-fluid" alt="banner" />
          )}
          {toggle&& thumbnail && (
            <img src={thumbnail} className="img-fluid" alt="banner" />
          )}
        </FormGroup>
        {editstatus ? (
          <Button
            className="my-4 btn-block"
            type="submit"
            color="warning"
            onClick={updatebanner}
          >
            Update Banner
          </Button>
        ) : (
          <Button
            className="my-4 btn-block"
            type="submit"
            color="warning"
            onClick={addbanner}
          >
            Add Banner
          </Button>
        )}
      </Form>
    </>
  );
};
export default AddBanner;