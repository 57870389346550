
import React, { useEffect, useState } from 'react'
import Dataservices from "../../services/requestApi";
import Header from "components/Headers/Header";
import { Card, Col, Container, Row,CardHeader,CardBody } from 'reactstrap';


function View(props) {

    const [data, setData] = useState()
    const getData = async () => {
        if (props.location.state) {
            const Id = props.location.state.ID;
            const fetchData = await Dataservices.getPujaOrderByID(Id);
            setData(fetchData.data);
            console.log(fetchData.data)
        }
    }
    useEffect(() => {
        getData();
    }, [])

    if (data == undefined) {
        return null
    }

    return (
        <>
            <Header show={false} />
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="9">
                                        <h3 className="mb-0">Puja Order- {data.order_id}</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <h4>Name: {data.contact_name}</h4>
                                <h4>Phone: {data.contact_phone}</h4>
                                <h4>Address: {data.contact_address}</h4>
                                <h4>Order Status: {data.status}</h4>
                                <h4>Puja Name: {data.puja_id.name}</h4>
                                <h4>Package Type: {data.puja_id.package_type}</h4>
                                <h4>Package Name: {data.package_id.title}</h4>
                                <h4>Package Description: {data.package_id.description}</h4>
                                <h4>Puja Amount: {data.puja_amount}</h4>
                                <h4>Puja Date: {data.puja_date.substr(0,10)}</h4>
                                {/* <h4>Puja End-Date: {data.puja_Enddate.substr(0,10)}</h4>
                                <h4>Pnadit want in pooja: {data.pandit}</h4> */}
                                <h4>Payment Status: {data.Payment_status}</h4>
                                <h4>Samagri Amount: {data.samagri_amount}</h4>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default View