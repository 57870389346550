import http from "./http-common";
const AuthToken = sessionStorage.getItem("Authtoken");

class DataService {
  // Login Services
  Login(data) {
    return http.get("admin/login?" + data);
  }
  //popup services
  PopupAdd(data){
    return http.post (`/pop_up/create`,data);
  }
  PopupUpdate(id,data){
    return http.patch(`/pop_up/update/${id}`,data);
  }
  PopupDelete(id) {
    return http.delete(`/pop_up/delete/${id}` + id);
  }
  getPop(id) {
    return http.get(`/pop_up/all`);
  }

  // Banner Services
  BannerAll() {
    return http.get(`/banner/admin/all`);
  }
  BannerAdd(data) {
    return http.post(`/banner/create`, data);
  }
  BannerUpdate(id, data) {
    return http.patch(`/banner/update/${id}`, data);
  }
  BannerDelete(id) {
    return http.delete(`/banner/delete/${id}`);
  }

  // Article Services
  ArticleAll() {
    return http.get(`/article/admin/all`);
  }
  ArticleAdd(data) {
    return http.post(`/article/create`, data);
  }
  ArticleUpdate(id, data) {
    return http.patch(`/article/update/${id}`, data);
  }
  ArticleDelete(id) {
    return http.delete(`/article/delete/${id}`);
  }

  // Filter Services
  FilterAll() {
    return http.get(`/filter/admin/all`);
  }
  FilterAdd(data) {
    return http.post(`/filter/create`, data);
  }
  FilterUpdate(id, data) {
    return http.patch(`/filter/update/${id}`, data);
  }
  FilterDelete(id) {
    return http.delete(`/filter/delete/${id}`);
  }

  // Category Services
  CategoryAll() {
    return http.get(`/category/admin/all`);
  }
  CategoryAdd(data) {
    return http.post(`/category/create`, data);
  }
  CategoryAllFilterById(id) {
    return http.get(`/category/id/${id}`);
  }
  CategoryUpdate(id, data) {
    return http.patch(`/category/update/${id}`, data);
  }
  CategoryDelete(id) {
    return http.delete(`/category/delete/${id}`);
  }

  // Pooja Booking Services
  PoojaBookingAll() {
    return http.get(`/puja/admin/all`);
  }
  PoojaBookingAdd(data) {
    return http.post(`/puja/create`, data);
  }
  PoojaBookingUpdate(id, data) {
    return http.patch(`/puja/update/${id}`, data);
  }
  PoojaBookingDelete(id) {
    return http.delete(`/puja/delete/${id}`);
  }

  PanditId(id) {
    return http.get(`/pandit/id/${id}`);
  }
  PanditAll() {
    return http.get(`/pandit/all`);
  }
  panditDelete(Id){
    return http.delete(`/pandit/delete/${Id}`);
  }


  // Pooja Booking Packages Services
  PoojaBookingAllPackages(id) {
    return http.get(`/puja_packages/puja/${id}`);
  }
  PoojaBookingAddPackges(data) {
    return http.post(`/puja_packages/create`, data);
  }
  PoojaBookingUpdatePackges(id, data) {
    return http.patch(`/puja_packages/update/${id}`, data);
  }
  PoojaBookingDeletePackges(id) {
    return http.delete(`/puja_packages/delete/${id}`);
  }

  // Product Services
  ProductAll() {
    return http.get(`/product/admin/all`);
  }

  async ProductAdd(data) {
    return http.post(`/product/create`, data);
  }
  ProductById(id) {
    return http.get(`/product/id/${id}`);
  }
  ProductUpdate(id, data) {
    return http.patch(`/product/update/${id}`, data);
  }
  ProductDelete(id) {
    return http.delete(`/product/delete/${id}`);
  }

  // Product Packages Services
  ProductAllVariant(id) {
    return http.get(`/product_variant/product/${id}`);
  }
  ProductCreateVariant(data) {
    return http.post(`/product_variant/create`, data);
  }
  ProductUpdateVariant(id, data) {
    return http.patch(`/product_variant/update/${id}`, data);
  }
  ProductDeleteVariant(id) {
    return http.delete(`/product_variant/delete/${id}`);
  }
  VarientById(id) {
    return http.get(`/product_variant/id/${id}`);
  }

  // pdf management
  PdfUpload(data) {
    return http.post(`/puja_pdf/create`, data);
  }
  getAllPdf() {
    return http.get(`/puja_pdf/all`);
  }
  deletePDF(Id) {
    return http.delete(`/puja_pdf/delete/${Id}`);
  }
  // user management
  getAllUser() {
    return http.get(`/user/user/all`);
  }
  deleteUser(Id) {
    return http.delete(`/user/delete/admin/${Id}`);
  }
  blockUnBlock(Id, data) {
    return http.patch(`/user/update/${Id}`, data);
  }
  // order management
  getAllOrder() {
    return http.get(`/order/admin/all`);
  }
  // deleteOrder(Id){
  //   return http.delete(`/user/delete/admin/${Id}`);
  // }
  updateProudctOrder(Id, data) {
    return http.patch(`/order/update/${Id}`, data);
  }
  // pujas orders
  getAllOrderPuja() {
    return http.get(`/puja/order/admin/all`);
  }
  deleteOrder(Id){
    return http.delete(`/order/delete/${Id}`);
  }
  deletePuja(Id){
    return http.delete(`/puja/delete/admin/${Id}`);
  }
  updateOrderPuja(Id, data) {
    return http.patch(`/puja/update/order/${Id}`, data);
  }
  getPujaOrderByID(id) {
    return http.get(`/puja/order/admin/${id}`);
  }
  getOrderByID(id) {
    console.log(id)
    return http.get(`/order/user/${id}`);
  }
  KundliGetAll() {
    return http.get(`kundali/admin`);
  }
  kundaliUpdate(ID,data) {
    return http.patch(`/kundali/update/${ID}`,data);
  }
  ShiprocketOrder(ID,data) {
    return http.patch(`/order/update/${ID}`, data);
  }
  kundaliDelete(Id){
    return http.delete(`/kundali/delete/${Id}`);
  }
  getInvoice(id) {
    return http.get(`/order/print/invoice/${id}`);
  }
}

export default new DataService();
