import React, { useState, useEffect, useCallback } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";
// APi
import Dataservices from "../../services/requestApi";
import queryString from "query-string";
// core components
import Header from "components/Headers/Header";
// import moment from "moment";
import moment from 'moment-timezone';

import Loader from "components/loader/Loader";
import { message } from "antd";



const Kundali = () => {
   
  const [kundaliData, setData] = useState([]);

  const getData=async ()=>{
    const promise = Dataservices.KundliGetAll();
    promise.then((res) => {
        console.log(res.data.Kundali)
      setData(res.data.Kundali);
    }
    ).catch((err) => {
      message.error(err.response.data.message);
    }
    );
  }
  useEffect(() => {
    //   console.log(moment.tz.names());
    getData();
  }, []);

  const updateProudctOrder = async (id, data) => {
    try {
      await Dataservices.kundaliUpdate(id, queryString.stringify(data));
      getData();
    } catch (e) {
      console.log(e);
    }
  }

  const deleteKundali= async (id)=>{
    try {
      await Dataservices.kundaliDelete(id);
      getData();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    kundaliData&&<>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--12" fluid>
       <div  className="col-md-12">

        <div className="card">
          <div className="card-header">
            <h3 className="mb-0">Kundali Info </h3>
          </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table align-items-center table-flush">
              <thead className="thead-light">
                <tr>
                  <th scope="col">S.N.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Package</th>
                  <th scope="col">Mobile</th>
                  <th scope="col">Email</th>
                  <th scope="col">Time of Birth</th>
                  <th scope="col">Date of Birth</th>
                  <th scope="col">Birth Place</th>
                  <th scope="col">Gender</th>
                  <th scope="col">Address</th>
                  <th scope="col">Query</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Order Placed</th>
                   <th scope="col">Status</th>
                   <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {[...kundaliData].reverse().map((item, index) => {
                  return (
                    <tr key={index}>
                     <td>{kundaliData.length-index}</td>
                      <td>{item.contact_name}</td>
                      <td>{item.Packege_Name}</td>
                      <td>{item.contact_phone}</td>
                      <td>{item.email}</td>
                      <td>{item.Time_of_Birth}</td>
                      <td>{moment(item.Date_of_Birth).format("Do MMM YYYY")}</td>
                      {/* <td>{item.DOB}</td> */}
                      <th>{item.BirthPlace}</th>
                      <th>{item.Gender}</th>
                      <th>{item.contact_address}</th>
                      <td>
                    <textarea className="border-0" name="" id="" cols="50" rows="5" readOnly>{item.query}</textarea>
                      </td>
                      <td>{item.amount}({item.Payment_status=="ACTIVE"?"Pending":item.Payment_status})</td>
                      <td>{moment(item.created_at).utcOffset("+00:00").format("llll")}</td>
                      <td>
                              <FormGroup className="w-75">
                                {/* <Label for="exampleSelect">
                                          Update Status
                                        </Label> */}
                                <Input
                                  id="exampleSelect"
                                  name="select"
                                  type="select"
                                  onChange={(e) => { updateProudctOrder(item._id, { status: e.target.value }) }}
                                  defaultValue={item.status}
                                >
                                  <option value=''>
                                    Update Status
                                  </option>
                                  <option value='In Process'>
                                    In Process
                                  </option>
                                  <option value='Order Dispatched'>
                                  Order Dispatched
                                  </option>
                                  <option value='Out for Delivery'>
                                  Out for Delivery
                                  </option>
                                  <option value='Order Delivered'>
                                  Order Delivered
                                  </option>
                                  <option value='Cancelled'>
                                    Cancelled
                                  </option>
                                </Input>
                              </FormGroup>
                            </td>
                            <td className="text-right">
                              <button className="btn btn-outline-danger btn-sm" onClick={()=>deleteKundali(item._id)}>
                                <i className="fas fa-trash text-danger" />Delete
                              </button>
                            </td>
                      
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>

      </Container>
    </>
  );
};

export default Kundali;
