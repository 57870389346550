import React, { useState } from 'react'
import { FormGroup, Input, Button, Form, Spinner } from "reactstrap";
import { message } from "antd";
import Dataservices from "../../services/requestApi";
import queryString from "query-string";

function PlacedOrder({ Id, hide }) {

  const [height, setHeight] = useState("");
  const [length, setLength] = useState("");
  const [breadth, setBreadth] = useState("");
  const [weight, setWeight] = useState("");
  const [ready, setReady] = useState(false);

  const submitOrder = async (event) => {
    event.preventDefault();
    setReady(true);
    const data = {
      height,
      length,
      breadth,
      weight,
      status: 'Order Dispatched'
    };
    try {
      const res = await Dataservices.ShiprocketOrder(Id, queryString.stringify(data));
      if (res.status === 200) {
        setReady(false);
        message.success("Order Placed Successfully");
        // refresh();
        hide(false);
      }
    } catch (e) {
      console.log(e);
      setReady(false);
      message.error("Failed to Place Order");
    }
  }
  return (
    <>
      <Form role="form" onSubmit={submitOrder}>
        {/* <FormGroup>
          <label className="form-control-label d-block" htmlFor="paid">
            Select Package Type
          </label>
          <Input
            type="select"
            id="paid"
            value={package_type}
            className="form-control-alternative"
            onChange={(e) => setPackageType(e.target.value)}
          >
            <option value="">Select ... </option>
            <option value="single">Single </option>
            <option value="multiple">Multiple</option>
          </Input>
        </FormGroup> */}

        <FormGroup>
          <label className="form-control-label" htmlFor="length">
            length
          </label>
          <Input
            className="form-control-alternative"
            id="length"
            placeholder="Length"
            type="text"
            value={length}
            onChange={(e) => setLength(e.target.value)}
            required
          />
        </FormGroup>

        <FormGroup>
          <label className="form-control-label" htmlFor="height">
            Height
          </label>
          <Input
            className="form-control-alternative"
            id="height"
            placeholder="Height"
            type="text"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            required
          />
        </FormGroup>

        <FormGroup>
          <label className="form-control-label" htmlFor="breadth">
            Breadth
          </label>
          <Input
            className="form-control-alternative"
            id="breadth"
            placeholder="Breadth"
            type="text"
            value={breadth}
            onChange={(e) => setBreadth(e.target.value)}
            required
          />
        </FormGroup>

        <FormGroup>
          <label className="form-control-label" htmlFor="weight">
            Weight
          </label>
          <Input
            className="form-control-alternative"
            id="weight"
            placeholder="Weight"
            type="text"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            required
          />
        </FormGroup>

        <Button
          className="my-4 btn-block"
          type="submit"
          color="warning"
          // onClick={SubmitPoojaBooking}
          disabled={ready}
        >
          {ready && <Spinner size="sm" color="light" />} Ship Order Now
        </Button>

      </Form>
    </>
  )
}

export default PlacedOrder