import TextEditor from "components/TextEditor";
import React, { useEffect, useState } from "react";
import { FormGroup, Input, Button, Form } from "reactstrap";
import { BASEURL } from "services/http-common";
import Resizer from "react-image-file-resizer";


const AddArticle = ({
  title,
  settitle,
  setimage,
  description,
  setdescription,
  addbanner,
  updatebanner,
  editstatus,
  thumbnail,
  setthumbnail,
}) => {
  const [toggle, setToggle] = useState(false)
  const fileHandle = (e) => {
    const file = e.target.files[0];
    if (file) {
       Resizer.imageFileResizer(
        e.target.files[0],
        500,
        500,
        "JPEG",
        50,
        0,
        (uri) => {
          setToggle(true)
          setimage(uri);
          const showiMG = URL.createObjectURL(file);
          setthumbnail(showiMG);
        },
        "file",
        500,
        500
      );
    }
  };
  useEffect(() => {
    if(thumbnail==null)
    {
      setToggle(false)
    }
  }, [thumbnail])
  
  return (
    <>
      <Form role="form" onSubmit={addbanner}>
        <FormGroup>
          <label className="form-control-label" htmlFor="title">
            Title
          </label>
          <Input
            className="form-control-alternative"
            id="title"
            placeholder="Enter Title"
            type="text"
            value={title}
            onChange={(e) => settitle(e.target.value)}
            required
          />
        </FormGroup>

        <FormGroup>
          <label className="form-control-label" htmlFor="image">
            Image
          </label>
          <Input
            className="form-control-alternative"
            id="image"
            type="file"
            accept="image/*"
            onChange={fileHandle}
            required
          />
        </FormGroup>
        <FormGroup>
        {!toggle&& thumbnail && (
            <img src={`${BASEURL.ENDPOINT_URL}${thumbnail}`} className="img-fluid" alt="default" />
          )}
          {toggle&& thumbnail && (
            <img src={thumbnail} className="img-fluid" alt="banner" />
          )}
        </FormGroup>

        <FormGroup>
          <label className="form-control-label" htmlFor="desc">
            Description
          </label>
          <TextEditor value={description} setvalue={setdescription} />
        </FormGroup>
        {editstatus ? (
          <Button
            className="my-4 btn-block"
            type="submit"
            color="warning"
            onClick={updatebanner}
          >
            Update Article
          </Button>
        ) : (
          <Button
            className="my-4 btn-block"
            type="submit"
            color="warning"
            onClick={addbanner}
          >
            Add Article
          </Button>
        )}
      </Form>
    </>
  );
};
export default AddArticle;
