import React, { useState, useEffect, useCallback } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";
// APi
import Dataservices from "../../services/requestApi";
import queryString from "query-string";
// core components
import Header from "components/Headers/Header";

import Loader from "components/loader/Loader";
import { message,Image } from "antd";
import {BASEURL} from "services/http-common";


const PanditData = () => {
  const [data, setData] = useState([]);
  const getData=async ()=>{
    const promise = Dataservices.PanditAll();
    promise.then((res) => {
      setData(res.data.Pandit);
    console.log("pandit",res);
    }
    ).catch((err) => {
      message.error(err.response.data.message);
    }
    );
  }
  useEffect(() => {
    getData();
  }, []);

  const deletePandit =async (id)=>{
    try {
      await Dataservices.panditDelete(id);
      getData();
    } catch (error) {
      console.log(error);
    }
  }

  if(data==undefined){
    return null
  }

  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--12" fluid>
       <div  className="col-md-12">

        <div className="card">
          <div className="card-header">
            <h3 className="mb-0">Pandit Info </h3>
          </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table align-items-center table-flush">
              <thead className="thead-light">
                <tr>
                <th>#</th>
                  <th scope="col">Name</th>
                  <th scope="col">E-mail</th>
                  <th scope="col">Address</th>
                    <th scope="col">Pincode</th>
                    <th scope="col">State</th>
                    <th scope="col">City</th>
                    <th scope="col">country</th>
                  <th scope="col">Education</th>
                  <th scope="col">Mobile</th>
                  <th scope="col">Date of Birth</th>
                  <th scope="col">Marksheet</th>
                  <th scope="col">Aadhar_Card_Image</th>
                  <th scope="col">profile_pic</th>
                  <th scope="col">status</th>
                </tr>
              </thead>
              <tbody>
                {data!=='undefined' &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                        <td>{index+1}</td>
                      <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.Address}</td>
                        <td>{item.pincode}</td>
                        <td>{item.state}</td>
                        <td>{item.city}</td>
                        <td>{item.country}</td>
                        <td>{item.Education}</td>
                        <td>{item.phone}</td>
                        <td>{item.DOB.slice(0,10)}</td>
                        <td>
                          <Image
                          src={BASEURL.ENDPOINT_URL + item.Marksheet}
                          alt="marksheet"
                          width={150}
                          // onClick={showImage()}
                          />
                          </td>
                        <td >
                          <Image
                          src={BASEURL.ENDPOINT_URL + item.Aadhar_Card_Image}
                          width={150}
                          />
                          
                        </td>
                        <td>
                          <Image src={BASEURL.ENDPOINT_URL + item.profile_pic}
                          width={150}
                          /></td>
                        <td className="text-right">
                              <button className="btn btn-outline-danger btn-sm" onClick={()=>deletePandit(item._id)}>
                                <i className="fas fa-trash text-danger" />Delete
                              </button>
                            </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>

      </Container>
    </>
  );
};

export default PanditData;
